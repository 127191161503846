<template>
<div id="vessel-overview" class="w-25 pe-2">
    <section id="bl-table-fixed">
    <h1 class="pb-3">{{$tc('navigation.bills_of_lading', 2)}}</h1>
    <div class="form-group search w-100 pe-3 mb-0 pb-4">
        <i class="fa fa-search" aria-hidden="true"></i>
        <input type="text" class="form-control" id="search2" :placeholder="$t('overview.search')" autocomplete="off" v-model="searchValue" @blur="filterVoyages" @keypress.enter="filterVoyages">
    </div>
    <div class="p-datatable-wrapper pe-3">
        <table class="p-datatable-table mb-0" role="table">
            <thead class="p-datatable-thead" role="rowgroup">
                <tr role="row" class="d-flex">
                    <th class="p-sortable-column p-highlight" tabindex="0" role="cell" :aria-sort="sortData.vessel" style="width: 60%" @click="sort('vessel')">
                        <div class="p-column-header-content clickable">
                            <span class="p-column-title">{{$t('navigation.vessels')}}</span>
                            <span class="p-sortable-column-icon pi pi-fw" 
                                 :class="{'pi-sort-amount-up-alt': sortData.vessel == 'ascending', 'pi-sort-amount-down': sortData.vessel == 'descending', 'pi-sort-alt': sortData.vessel == 'none'}">
                            </span>
                        </div>
                    </th>
                    <th class="p-sortable-column ps-1" tabindex="0" role="cell" :aria-sort="sortData.number" style="width: 40%" @click="sort('number')">
                        <div class="p-column-header-content clickable">
                            <span class="p-column-title">{{$t('navigation.voyages')}}</span>
                            <span class="p-sortable-column-icon pi pi-fw" 
                                 :class="{'pi-sort-amount-up-alt': sortData.number == 'ascending', 'pi-sort-amount-down': sortData.number == 'descending', 'pi-sort-alt': sortData.number == 'none'}">
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody class="p-datatable-tbody" role="rowgroup" id="scrolltable">
                <tr role="row" v-for="voyage in voyages" :key="voyage.id" @click="onOverviewSelect(voyage)" :class="{'selected': selectedVoyage.id == voyage.id, 'd-flex': voyage.show, 'd-none': !voyage.show}">
                    <td role="cell" style="width: 60%">{{voyage.vessel}}</td>
                    <td role="cell" style="width: 40%">{{voyage.number}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    </section>
</div>
<div id="new-bl-overview" class="w-75 ps-2">
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :filterOptions="filterOptions"
        :showName="false"
        :sort="'number'"
        :sortOrder="1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :modalSize="'modal-xl'"
        :modalWidth="'1400px'"
        :modalHeight="'90%'"
        :selectFields="selectFields"
        :showAction="true"
        :extraOptions=extraOptions
        :autoSaveEnabled="true"
        :blueHeader="true"
        ref="overviewref"
        downloadFileType="pdf"
        
    >
        <template #columns>
            <Column field="number" :header="$t('overview.number')" style='width: 180px'>
                <template #body="slotProps" v-if="slotProps && slotProps.data.job_booking" >
                    <a href="#" class="above-overlay" @click="goToBL(slotProps.data)">{{slotProps.data.number}}</a>
                </template>
                <template #body="slotProps" v-else >
                    <div :class="{'isExternal': (slotProps.data.legacy_shipload_id||slotProps.data.legacy_shipload_id)}">{{slotProps.data.number}}</div>
                </template>
            </Column>
            <Column header="Pol" field="port_of_loading.code" sortField="port~port_of_loading.code" style='width: 55px'>
                <template #body="slotProps">
                    <div :class="{'isExternal': (slotProps.data.legacy_shipload_id||slotProps.data.legacy_shipload_id)}">{{slotProps.data.port_of_loading ? slotProps.data.port_of_loading.code : '-'}}</div>
                </template>
            </Column>
            <Column header="Pod" field="port_of_discharge.code" sortField="port~port_of_discharge.code" style='width: 55px'>
                <template #body="slotProps">
                    <div :class="{'isExternal': (slotProps.data.legacy_shipload_id||slotProps.data.legacy_shipload_id)}">{{slotProps.data.port_of_discharge ? slotProps.data.port_of_discharge.code : '-'}}</div>
                </template>
            </Column>
            <Column header="Pofd" field="port_of_final_destination.code" style='width: 60px'>
                <template #body="slotProps">
                    <div :class="{'isExternal': (slotProps.data.legacy_shipload_id||slotProps.data.legacy_shipload_id)}">{{slotProps.data.port_of_final_destination ? slotProps.data.port_of_final_destination.code : '-'}}</div>
                </template>
            </Column>
            <Column field="customer_name" :header="$t('navigation.customers')">
                <template #body="slotProps">
                    <div :class="{'isExternal': (slotProps.data.legacy_shipload_id||slotProps.data.legacy_shipload_id)}">{{slotProps.data.customers ? slotProps.data.customers : slotProps.data.customer_name}}</div>
                </template>
            </Column>
            <Column field="bill_of_lading_status.name" :header="$t('overview.status')" style='width: 75px'>
                <template #body="slotProps">
                    <span class="ms-3" :class="{'isExternal': (slotProps.data.legacy_shipload_id||slotProps.data.legacy_shipload_id)}">{{ slotProps.data.bill_of_lading_status.name[0].toUpperCase() }}</span>
                </template>
            </Column>
            <Column field="bl_type" :header="$t('overview.type')" style='width: 60px'>
                <template #body="slotProps">
                    <div :class="{'isExternal': (slotProps.data.legacy_shipload_id||slotProps.data.legacy_shipload_id)}">
                    {{ slotProps.data.bl_type == 'Original' ? (slotProps.data.one_third_per_shipsmail ? 'O 1/3' : 'O') : 'SW' }}
                    </div>
                </template>
            </Column>
            <Column field="amount_collect" :header="$t('operations.payment')" style='width: 140px'>
                <template #body="slotProps">
                    <div :class="{'isExternal': (slotProps.data.legacy_shipload_id||slotProps.data.legacy_shipload_id)}">
                        {{ slotProps.data.payment_option == 'COLLECT' ? ( slotProps.data.collected_currency && slotProps.data.amount_collect ? (slotProps.data.collected_currency.code + ' ' + formatCurrency(slotProps.data.amount_collect)) : 'Collect') : 'Prepaid' }}
                    </div>
                </template>
            </Column>
            <Column :header="$tc('navigation.units', 2)" style='width: 60px'>
                <template #body="slotProps">
                    <div :class="{'isExternal': (slotProps.data.legacy_shipload_id||slotProps.data.legacy_shipload_id)}">
                        {{ formatNumber(slotProps.data.job_units_count) }}
                    </div>
                </template>
            </Column>
            <!--<Column :header="$t('operations.date_loaded')" field="loaded_units_count" style='width: 70px'></Column>
            <Column field="volume" header="Vol." :sortable="false" style='width: 70px'>
                <template #body="slotProps">
                    {{ (slotProps.data.volume ? slotProps.data.volume.replaceAll(',', '').replaceAll('.', '').slice(0, -3) : 0) }}
                </template>
            </Column>
            <Column field="weight" header="Wt. (ts)" :sortable="false" style='width: 70px'>
                <template #body="slotProps">
                    {{ Math.round(Number(slotProps.data.weight ? slotProps.data.weight : 0) / 1000) }}
                </template>
            </Column>-->
        </template>

        <template #form>
            <BillOfLadingForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <button id="prevbutton" type="button" class="btn btn-secondary" style="margin: auto auto auto 0" @click.prevent="prev">&lt;&ensp;{{$t('form.nav_prev')}}</button>
            <FormItem v-if="itemFormRef" type="download" id="savedownloadbutton" :label="$t('form.save_download')" startTag="fal fa-file-pdf" :service="saveAndDownloadPDF" />
            <button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">{{$t('form.nav_next')}}&ensp;&gt;</button>
        </template>
    </Overview>
</div>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import BillOfLadingForm from '@/components/jobs/BillOfLadingFormNew.vue';
import billofladingService from '@/services/BillOfLadingService';
import officeService from '@/services/FinanceSettingService';
import portService from '@/services/PortService'
import voyageService from '@/services/VoyageService';
import router from '@/router';

export default {
    components: {
        Overview,
        Column,
        BillOfLadingForm
    },
    data() {
        return {
            item: {},
            itemService: billofladingService,
            voyageService: voyageService,
            itemFormRef: null,
            voyages: [],
            selectedVoyage: {id: null, name: null},
            extraOptions: [],
            selectFields: [
                "id",
                "number",
                "bl_type",
                "one_third_per_shipsmail",
                "job_booking_id",
                "shipper_id",
                "amount_collect",
                "amount_collect_currency_id",
                "payment_option",
                "reference",
                "shipper_name",
                "created_at",
                "voyage_id",
                "customer_id",
                "customer_name",
                "legacy_shipload_id",
                "legacy_booking_bl_id",
                "port_of_loading_id",
                "port_of_discharge_id",
                "port_of_final_destination_id",
                "bill_of_lading_status_id",
                {
                    "voyage": [
                        "id",
                        "number"
                    ]
                },
                {
                    "shipper": [
                        "id",
                        "name"
                    ]
                },
                {
                    "collectedCurrency": [
                        "id",
                        "code"
                    ]
                },
                {
                    "billOfLadingStatus": [
                        "id",
                        "name"
                    ]
                },
                {
                    "portOfLoading": [
                        "id",
                        "code"
                    ]
                },
                {
                    "portOfDischarge": [
                        "id",
                        "code"
                    ]
                },
                {
                    "portOfFinalDestination": [
                        "id",
                        "code"
                    ]
                },
                {
                    "customer": [
                        "id",
                        "relation_id",
                        {
                            "relation": [
                                "relation.id",
                                "relation.name"
                            ]
                        }
                    ]
                },
                {
                    "jobBooking":[
                        "id",
                        "job_id",
                        "number",
                        {
                            "voyages": [
                                "voyages.id",
                                "voyages.number"
                            ]
                        },
                        {
                            "job": [
                                "jobs.id",
                                "jobs.number"
                            ]
                        }
                    ]
                }
            ],
            sortData: {
                "vessel": "ascending",
                "number": "none"
            },
            filterOptions: [
                {
                    field: 'voyage.id',
                    service: voyageService,
                    name: this.$t('navigation.voyages'),
                    hidden: true
                },

                {
                    field: 'customer_name',
                    like: true,
                    name: this.$t('navigation.customers')
                },
                {
                    field: 'jobUnits.license_plate',
                    capitals: true,
                    name: this.$t('sales.license_plate')
                },
                {
                    field: 'office_id',
                    service: officeService,
                    name: this.$t('navigation.offices')
                },
                {
                    field: 'portOfLoading.id',
                    service: portService,
                    name: this.$t('sales.loading_ports')
                },
                {
                    field: 'portOfDischarge.id',
                    service: portService,
                    name: this.$t('sales.discharge_ports')
                },
                {
                    field: 'shipper_name',
                    like: true,
                    name: this.$t('operations.shipper')
                },
                {
                    field: 'consignee_name',
                    like: true,
                    name: this.$t('operations.consignee')
                },
                {
                    field: 'notify_name',
                    like: true,
                    name: this.$t('operations.notify')
                },
                {
                    field: 'bill_of_lading_status_id',
                    radio: [{id: 1, name: this.$t('bl_status.1')}, {id: 2, name: this.$t('bl_status.2')}, {id: 3, name: this.$t('bl_status.3')}],
                    name: this.$t('overview.status')
                },
                {
                    field: 'jobUnits.chassis_number',
                    capitals: true,
                    like: true,
                    name: this.$t('sales.vin') + " (Last 6 chars)"
                }
            ],
            searchValue: ""
        }
    },
    methods: {
        getVoyages(data){
            let names = [];
            if(data.voyage){
                return data.voyage.number;
            }
            if(!data.job_booking){
                return '-';
            }
            data.job_booking.voyages.forEach(voyage => {
                if(!names.includes(voyage.number)) names.push(voyage.number);
            });
            return names.join(', ');
        },
        goToBL(bl){
            window.tabToOpen = bl.job_booking.number;
            //window.tabToOpen = 0;
            window.BLToOpen = bl.number;
            window.setTimeout(() => {
                router.push('jobs#' + bl.job_booking.job_id);
            }, 100);
        },
        next() {
            let tabs = document.querySelectorAll("#myTab3-0 .nav-link");
            let next = false;
            for(let tab of tabs) {
                if(next) {
                    tab.click();
                    break;
                }
                if(tab.className == "nav-link active") next = true;
                
            }
        },
        prev() {
            let tabs = document.querySelectorAll("#myTab3-0 .nav-link");
            let prev = false;
            for(let tab of tabs) {
                if(tab.className == "nav-link active" && prev){
                    prev.click();
                    break;
                }
                prev = tab;
            }
        },
        saveAndDownloadPDF(){
            const form = this.itemFormRef.$refs.billofladingref;
            return form.saveAndDownloadPDF();
        },
        filterVoyages(){
            this.searchValue = this.searchValue.replaceAll(/[^A-Z0-9\s]/gi, '');
            window.documentationSearch = this.searchValue;
            const regex = new RegExp(this.searchValue.match(/^\d+$/) ? ('[A-Z0]'+this.searchValue+'$') : this.searchValue, 'i');
            this.voyages.forEach(voyage => {
                voyage.show = regex.test(voyage.name);
            });
        },
        sort(field){
            if(field == 'number'){
                this.sortData.vessel = "none";
                this.sortData.number = (this.sortData.number == "none" || this.sortData.number == "descending" ? "ascending" : "descending");
            }
            else{
                this.sortData.number = "none";
                this.sortData.vessel = (this.sortData.vessel == "none" || this.sortData.vessel == "descending" ? "ascending" : "descending");
            }
            this.voyages.sort((a, b) => {
                return this.sortData[field] == "descending" ? (a[field] < b[field]) : (a[field] > b[field]);
            });
        },
        onOverviewSelect(e){
            const filters = this.$refs.overviewref.$refs.overviewfilters;
            const voyageFilter = 0;
            if(this.selectedVoyage && this.selectedVoyage.number == e.number){
                this.selectedVoyage = {id: null, name: null};
                //this.$refs.overviewref.rows = 18;
                filters.filterData[voyageFilter].active = false;
                filters.save();
                return;
            }
            this.selectedVoyage = {id: e.id, name: e.vessel + ' ' + e.number, number: e.number, vessel: {name: e.vessel}};
            this.$refs.overviewref.filters['global'].value = '';
            //this.$refs.overviewref.rows = 100;
            for(let filter in filters.filterData){
                filters.filterData[filter].active = false;
            }
            filters.filterData[voyageFilter].active = true;
            filters.filterData[voyageFilter].value = e.id;
            filters.save();
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        if(window.documentationSearch){
            this.searchValue = window.documentationSearch;
        }
        this.extraOptions = [{
            icon: 'download',
            name: this.$t('form.download', ["PDF (no background)"]),
            function: (slotProps) => {
                billofladingService.downloadPDF(slotProps.data.id, 0).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${slotProps.data.number}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                }).catch(error => {
                    this.toastError(error)
                });
            }
        }];
        voyageService.indexNames().then(response => {
            response.data.forEach(voyage => {
                const i = voyage.name.lastIndexOf(" ");
                this.voyages.push({
                    id: voyage.id,
                    vessel: voyage.name.substring(0, i),
                    number: voyage.name.substring(i + 1),
                    name: voyage.name,
                    show: true
                });
            });
            if(this.searchValue){
                this.filterVoyages();
            }
        }).catch(e => {
            console.log(e);
        });
    }
}
</script>

<style scoped>
.above-overlay{
    z-index: 2;
    position: relative
}
#vessel-overview .p-datatable-wrapper, #vessel-overview .form-group{
    border-right: 1px solid #ddd
}
#bl-table-fixed{
    width: calc((100vw - 360px) / 4);
    position: fixed;
}
#scrolltable{
    height: calc(100vh - 13rem);
    overflow: hidden auto;
    display: block;
    width: 100%;
}
#scrolltable tr:hover{
    background-color: #eef8ff;
    cursor: pointer
}
#scrolltable tr.selected{
    background-color: #e3f3ff !important;
}
</style>
<style>
#new-bl-overview .p-datatable-header{
    position: fixed;
    top: 0;
    width: calc((100vw - 290px) * 0.75);
    background-color: white;
    padding-top: 15px;
    z-index: 10;
    padding-right: 15px
}
#new-bl-overview .p-datatable-wrapper{
    margin-top: 133px;
}
#new-bl-overview tr.selected{
    background-color: #eef8ff !important;
}
.isExternal{
    color: #1565c0 !important
}
</style>